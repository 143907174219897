<template>
  <div class="history-planning">
    <div class="filter-history-planning">
      <b-form-group label="Poseur :" class="mr-2">
        <Multiselect
          class="multi-select-history-poseur"
          v-model="poseur"
          label="text"
          track-by="value"
          :showLabels="false"
          :options="[{ text: '', value: 'vide' }, ...options]"
          :max-height="600"
          :loading="getProjectsUsersTechniciensLoading"
          placeholder="Rechercher Poseur"
        >
          <span slot="noResult">Aucun poseur trouvé.</span>
          <span slot="noOptions">Aucun poseur trouvé.</span>
        </Multiselect>
      </b-form-group>

      <b-form-group label="Date :" class="mr-2">
        <date-picker
          class="style-date"
          id="date-input"
          v-model="date"
          type="date"
          format="YYYY-MM-DD"
          value-type="format"
          placeholder="Sélectionner la date"
        ></date-picker>
      </b-form-group>

      <b-form-group label="Modification :" class="mr-2">
        <Multiselect
          class="multi-select-history-poseur"
          v-model="selected"
          :showLabels="false"
          :options="computedModificationList"
          placeholder="List de modification"
          required
          track-by="value"
          label="name"
        >
        </Multiselect>
      </b-form-group>

      <b-button variant="success" type="submit" @click="filter" class="mt-2">
        Valider
      </b-button>
    </div>
    <div class="content-tab-history-planning-poseur">
      <b-table
        small
        show-empty
        id="my-table-history"
        class="table-history-planning-poseur"
        :items="getListHistoryPlanningPoseur"
        :fields="fields"
        :current-page="page"
        :per-page="0"
        empty-text="Il n'y a aucun enregistrement à afficher"
        empty-filtered-text="Aucun enregistrement ne correspond à votre demande"
      >
        <template v-slot:cell(nom)="data">
          <div class="nom_user">{{ data.item.user.full_name }}</div>
        </template>
        <template v-slot:cell(key)="data">
          <div class="nom_user">{{ data.item.key }}</div>
        </template>
        <template v-slot:cell(precedent)="data">
          <div class="nom_user">{{ data.item.previous }}</div>
        </template>
        <template v-slot:cell(current)="data">
          <div class="nom_user">{{ data.item.current }}</div>
        </template>
        <template v-slot:cell(date_modif)="data">
          <div class="nom_user">
            {{ data.item.timestamp | date }}
          </div>
        </template>
      </b-table>
      <div class="pagination">
        <div
          v-if="getHistoryPlanningPoseurLoading"
          class="three-dots-loading loading"
        >
          Chargement en cours
        </div>
        <b-pagination
          v-model="page"
          :per-page="per_page"
          :total-rows="countPaginationPlanningPoseur"
          align="right"
          size="sm"
          prev-text="Précédent"
          next-text="Suivant"
          aria-controls="my-table-history"
          @change="pagination"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import * as moment from 'moment'
moment.locale('fr')
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      poseur: null,
      date: null,
      fields: [
        {
          key: 'nom',
          label: 'Utilisateur',
          thClass: 'width-th-nom',
          tdClass: 'width-th-nom'
        },
        {
          key: 'key',
          label: 'Modification',
          thClass: 'width-th-key',
          tdClass: 'width-th-key'
        },
        {
          key: 'precedent',
          label: 'Avant',
          thClass: 'width-th-precedent',
          tdClass: 'width-th-precedent'
        },
        {
          key: 'current',
          label: 'Après',
          thClass: 'width-th-current',
          tdClass: 'width-th-current'
        },
        {
          key: 'date_modif',
          label: 'Date de Modification',
          thClass: 'width-th-date',
          tdClass: 'width-th-date'
        }
      ],
      page: 1,
      per_page: 10,
      selected: null,
      optimised: 0,
      modificationList: [
        {
          name: 'Optimisation',
          value: 0,
          $isDisabled: false
        },
        {
          name: 'Ligne 1',
          value: 1,
          $isDisabled: false
        },
        {
          name: 'Ligne 2',
          value: 2,
          $isDisabled: false
        },
        {
          name: 'Ligne 3',
          value: 3,
          $isDisabled: false
        },
        {
          name: 'Ligne 4',
          value: 4,
          $isDisabled: false
        },
        {
          name: 'Ligne 5',
          value: 5,
          $isDisabled: false
        }
      ]
    }
  },
  methods: {
    ...mapActions([
      'fetchUsersTechniciens',
      'fetchListHistoryPlanningPoseur',
      'resetHistoryPlanningPoseur'
    ]),
    pagination(pagination) {
      this.page = pagination
      this.fetchListHistoryPlanningPoseur({
        page: this.page,
        per_page: this.per_page,
        optimised: this.selected && this.selected.value === 0 ? 1 : 0,
        technician_id: this.poseur,
        row_index:
          this.selected && this.selected.value > 0 ? this.selected.value : null,
        day: this.date
      })
    },

    filter() {
      this.page = 1
      this.fetchListHistoryPlanningPoseur({
        page: this.page,
        per_page: this.per_page,
        optimised: this.selected && this.selected.value === 0 ? 1 : 0,
        technician_id: this.poseur,
        row_index:
          this.selected && this.selected.value > 0 ? this.selected.value : null,
        day: this.date
      })
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsUsersTechniciensLoading',
      'getHistoryPlanningPoseurLoading',
      'getProjectsUsersTechniciens',
      'getListHistoryPlanningPoseur',
      'countPaginationPlanningPoseur',
      'getHistoryPlanningPoseurError'
    ]),
    options: function() {
      if (this.getProjectsUsersTechniciens) {
        return this.getProjectsUsersTechniciens.map(u => {
          return {
            text: u.full_name,
            value: u.id
          }
        })
      }
      return []
    },
    computedModificationList: function() {
      return this.modificationList.map(option => {
        if (option.value === 0) {
          option.$isDisabled =
            this.selected && this.selected.value > 0 ? true : false
        } else {
          option.$isDisabled =
            this.selected && this.selected.value === 0 ? true : false
        }
        return option
      })
    }
  },

  mounted() {
    this.fetchUsersTechniciens(), this.resetHistoryPlanningPoseur()
  },
  filters: {
    date: function(value) {
      if (!value) return ''
      value = moment(value, 'YYYY-MM-DD HH:mm:ss').format(
        'DD MMM YYYY, HH:mm:ss'
      )
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.history-planning {
  .filter-history-planning {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .table-history-planning-poseur {
    text-align: center;
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    width: 100%;
    .loading {
      width: 50%;
    }
  }
}
</style>
<style lang="scss">
.multi-select-history-poseur {
  width: 200px;
}
.style-date {
  .mx-input-wrapper {
    .mx-input {
      height: 42px !important;
      border-color: #e8e8e8;
      box-shadow: none;
    }
    .mx-input::placeholder {
      color: #adadad;
    }
  }
}
.content-tab-history-planning-poseur {
  .width-th-nom,
  .width-th-key,
  .width-th-precedent,
  .width-th-current,
  .width-th-date {
    height: 39px;
  }

  .table-sm th,
  .table-sm td {
    vertical-align: middle;
    border: 1px solid #dee2e6;
  }
}
@media screen and (max-width: 580px) {
  .content-tab-history-planning-poseur {
    margin-top: 8px;
    overflow: auto;
    width: 100%;
  }
}
</style>
